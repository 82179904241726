::-webkit-scrollbar {
  width: 10px;
}


::-webkit-scrollbar-track {
  background: #0e0e0f;
}


::-webkit-scrollbar-thumb {
  background: rgb(58, 58, 58);
}


::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.clip-triangle {
  clip-path: polygon(0% 0%,0% 100%,100% 0%);
}

.placide {
  -webkit-text-stroke: 2px #ffffff;
  color: transparent;
}

.txt-rotate > .wrap { 
  border-right: 0.08em solid #666;
}

.page__title {
  position: relative;
}
.page__title::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 150px;
  height: 4px;
  background-image: linear-gradient(45deg, #FC466B, #FFAC5F);
}

.page__title_center {
  position: relative;
}
.page__title_center::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 4px;
  background-image: linear-gradient(45deg, #FC466B, #FFAC5F);
}

.about__link {
  background: -webkit-linear-gradient(45deg, #FC466B, #FFAC5F);
  background-clip: inherit;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about__hero {
  position: relative;
  z-index: 1;
}
.about__hero::before {
  content: '';
  height: 100px;
  width: 100px;
  background-color:#FC466B;
  z-index: 200;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
} 

.card {
  position: relative;
  overflow: hidden;
}

.card__content {
  opacity: 0;
}

.card__content > * {
  z-index: 3;
}
.card::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: -12px;
  right: -12px;
  background: #2E2E2E;
  height: 16px;
  width: 16px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 250ms ease-out;
}

.card:hover::before {
  transform: scale(100);
}

.card:hover .card__content {
  transition: opacity 330ms cubic-bezier(0.79,.2,0.49,0.64) 50ms;
  opacity: 1;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  * {
    @apply box-border;
  }

  html {
    @apply h-full;
    @apply m-0;
    @apply p-0;
    /* @apply overflow-x-hidden;
    @apply overflow-y-auto; */
  }

  body {
    @apply h-full;
    @apply m-0;
    @apply p-0;
    @apply bg-primary-black;
    @apply font-Montserrat;
    /* @apply overflow-x-hidden;
    @apply overflow-y-auto; */
  }

  #root {
   /*  @apply w-full; */
    @apply h-full;
  }

  .app {
   /*  @apply w-full; */
    @apply h-full;
  }

  .hero__canvas {
    @apply h-screen;
    @apply bg-canvas-grey;
  }
}